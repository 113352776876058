<template>
  <div class="container">
    <div class="infoList" 
    v-infinite-scroll="loadData" 
    infinite-scroll-disabled="disabled" >  
        <div class="item" 
        v-for="item in infoList" 
        :key="item.article_id" 
        @click="openBlank('WapDetails',item.article_id)">
          <div class="update-time">
            <div class="day">{{item.update_time | formatDay}}</div>
            <div class="year">{{item.update_time | formatDate}}</div>
          </div>
          <div class="info_text">
            <div class="title">
              <span>{{item.title}}</span>
            </div>
            <div class="paper">
              <span>{{item.content}}</span>
            </div>
          </div>
        </div>	
        <div v-if="disabled && !!infoList.length" class="no_more">-没有更多了-</div>
        <el-empty description="暂无数据" v-if="!infoList.length"></el-empty>        
    </div>
    
  </div>
  
</template>

<script>
import { formatDate } from "@/utils/timeStamp.js";
export default {
  // 格式化时间戳
  filters: {
    formatDay(time) {
      time = time * 1000;
      let date = new Date(time);
      return formatDate(date, "dd");
    },
    formatDate(time) {
      time = time * 1000;
      let date = new Date(time);
      return formatDate(date, "yyyy.MM");
    },
  }, 
  data() {
    return {
      type:this.$route.query.type?this.$route.query.type:'examSchedule',
      page:1,
      infoList:[],
      loading:false,
      noMore:false,
    };
  },
  components: {},
  mounted(){
		// this.getNewsList();
	},
  computed: {
    disabled () {
      return this.loading || this.noMore
    }
  },
  methods:{
    //上拉加载
    loadData(){
      this.loading = true
      setTimeout(() => {
        this.getNewsList()
        this.loading = false
      }, 500)
    },    
    //通知列表
    getNewsList() {	
      this.$http.get('/api/wap/news/getNoticeList?type='+this.type+'&page='+this.page)
      .then((res) => {
        const {list,totalPage }=res.data;
        this.infoList = this.infoList.concat(list);        
        this.page++;
        if (this.page > totalPage) {
					this.noMore=true;
				} 
      })			
    },
		openBlank(name,id){				
      let routeUrl = this.$router.resolve({
        name,
        query:{id},	
      });
      window.open(routeUrl.href, '_self');
    },
  }
};
</script>

<style lang='less' scoped>
.container{
  position: relative;
  width: 100%;
  z-index: 1;
  /deep/.infoList {
    position: relative;
    width: 100%;
    min-height: calc(100vh - 7.24rem);
    padding: .30rem .24rem;    
    .item {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: .25rem;
      background-color: #fff;
      margin-bottom: .40rem;
      border-radius: .15rem;
      &.item:last-child {
        margin-bottom: 0;
      }
      .update-time {
        width: 1.40rem;
        height: 1.50rem;
        padding-top: .1rem;
        background-color: #d71917;
        border-radius: .12rem;
        .day {
          font-size: .80rem;
          font-weight: 700;
          text-align: center;
          color: #ffffff;
        }
        .year {
          font-size: .24rem;
          text-align: center;
          color: #ffffff;
        }
      }
      .info_text {
        width: calc(100% - 1.70rem);
        .title {
          font-size: .30rem;
          text-align: left;
          color: #333333;
          width: 100%;
          line-height: .50rem;
          margin-bottom: .20rem;
          overflow: hidden;
          text-align: left;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          text {
            color: #333333;
          }
        }
        .paper {
          width: 100%;
          font-size: 0.26rem;
          text-align: left;
          color: #999999;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          text {
            color: #999999;
          }
        }
      }
    }
    .u-empty {
      width: 100%;
      height: 1.60rem;
      margin-top: 1.50px !important;
    }
    .no_more{
      font-size: .28rem;
      color: #666;
    }
  }
}
</style>

